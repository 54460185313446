<script src="../assets/js/utils.js"></script>
<template>
    <div class="login">
        <div class="texture">
            <Row class="login_wrapper">
                <Col span="12">
                    <div class="login_logo">
                        <img src="../assets/images/login_logo.png" alt="资产管理系统">
                    </div>
                    <div class="login_image">
                        <img src="../assets/images/login_image.png" alt="">
                    </div>
                </Col>
                <Col span="12">
                    <div class="login_form">
                        <h1>用户登录</h1>
                        <Form ref="user" :model="user" :rules="ruleInline">
                            <FormItem prop="username">
                                <Input type="text" size="large" v-model.trim="user.username" placeholder="账号"
                                       @keyup.enter.native="handleLogin">
                                    <Icon type="ios-person" slot="prefix"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <Input type="password" size="large" v-model="user.password" clearable placeholder="密码"
                                       @keyup.enter.native="handleLogin">
                                    <Icon type="ios-lock" slot="prefix"></Icon>
                                </Input>
                            </FormItem>
                            <FormItem prop="captcha">
                                <Input class="captcha" type="text" size="large" prop="imgCode" maxlength="4"
                                       v-model="user.captcha" placeholder="请输入验证码" @keyup.enter.native="handleLogin">
                                    <template slot="append">
                                        <img class="img_code" :src="myCodeImg" @click="showCodeImg"/>
                                    </template>
                                </Input>
                            </FormItem>
                            <FormItem class="checkbox">
                                <Checkbox v-model="checked">记住用户名</Checkbox>
                                <Tooltip content="请联系系统管理员" placement="top-end">
                                    忘记密码？
                                </Tooltip>
                            </FormItem>
                            <FormItem>
                                <Button type="primary" size="large" @click.native.prevent="handleLogin"
                                        :loading="loading"
                                        long>登录
                                </Button>
                            </FormItem>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row class="copyright">
                <p>Copyright &copy; {{ (new Date).getFullYear() }} 保留所有权利<br>
                    最佳使用环境：操作系统 Win 7 以上、MACOSX，分辨率1440 x 900以上 | 浏览器：IE 10以上、Edge、Chrome 55以上、Firefox 50.0以上、Safari
                    9.0以上
                </p>
            </Row>
        </div>
    </div>
</template>

<script>
import {reqSystemLogin, reqCodeImg} from '../api/system-api';
import {encode64} from '../assets/js/utils';

export default {
    name: "login",
    data() {
        return {
            loading: false,
            user: {
                username: '',
                password: '',
                captcha: ''
            },
            myCodeImg: null,
            captchaImage: '',
            ruleInline: {
                username: [{required: true, message: '请输入账号', trigger: 'blur'}],
                password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                captcha: [{required: true, message: '请输入验证码', trigger: 'blur'}]
            },
            checked: true
        }
    },
    methods: {
        //记住用户
        setCookie(username, exdays) {
            let exdate = new Date()
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)// 保存的天数
            // 字符串拼接cookie
            window.document.cookie = 'username' + '=' + username + ';path=/;expires=' + exdate.toGMTString()
        },
        // 读取cookie
        getCookie() {
            document.cookie?.split(';').map(item => {
                if (item.startsWith('username')) {
                    this.user.username = item.split('=')[1];
                }
            })
        },
        // 清除cookie
        clearCookie() {
            this.setCookie('', -1);
        },
        //登录
        handleLogin() {
            this.$refs.user.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    let password = encode64(encode64(this.user.password) + 'pd');
                    let username = encode64(encode64(this.user.username) + 'un');
                    let loginParams = {
                        username: username,
                        password: password,
                        captcha: this.user.captcha,
                        captchaImage: this.captchaImage
                    };
                    reqSystemLogin(loginParams).then(data => {
                        this.loading = false;
                        if (data.code === 1) {
                            this.$Message.success(data.msg);
                            sessionStorage.setItem('un', this.user.username);
                            sessionStorage.setItem('Authorization', data.data.token);
                            if (this.checked == true) {
                                this.setCookie(this.user.username, 7);
                            } else {
                                this.clearCookie();
                            }
                            this.$router.push({path: '/'});
                        } else if (data.code === 8) {
                            this.$Message.success(data.msg);
                            sessionStorage.setItem('un', this.user.username);
                            sessionStorage.setItem('Authorization', data.data.token);
                            if (this.checked == true) {
                                this.setCookie(this.user.username, 7);
                            } else {
                                this.clearCookie();
                            }
                            this.$router.push({path: '/UnauthorizedPage'});
                        } else {
                            this.$Message.error(data.msg);
                            this.showCodeImg();
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.showCodeImg();
                        this.$Message.error('登录异常');
                    })
                }
            });
        },

        //展示验证码
        showCodeImg() {
            let that = this;
            reqCodeImg().then((res) => {
                that.myCodeImg = window.URL.createObjectURL(new Blob([res.data]));
                that.captchaImage = res.headers['captcha_key'];
            }).catch(() => {
                that.myCodeImg = null;
                that.captchaImage = "";
                this.$Message.error('获取验证码失败');
            });
        }
    },
    mounted() {
        this.getCookie();
        this.showCodeImg();
    }
}
</script>

<style lang="less">
// Related style
@import '../index.less';

.login {
    height: 100%;
    background-color: @body-background;
    background-image: url(../assets/images/bg.jpg);
    background-repeat: repeat-x;
    background-position: left top;

    .texture {
        height: 100%;
        background-image: url(../assets/images/texture.png);
        background-repeat: no-repeat;
        background-position: top center;

        .login_wrapper {
            width: 1080px;
            margin: 0 auto;
            padding-top: 12%;

            .login_logo {
                // margin-top: 20px;
                text-align: center;

                // img {
                //     width: 70%;
                // }
            }

            .login_image {
                margin-top: 20px;
                text-align: center;

                img {
                    width: 80%;
                }
            }

            .login_form {
                h1 {
                    padding-bottom: 20px;
                    font-size: 16px;
                    font-weight: 600;
                }

                min-height: 330px;
                margin: 0 60px;
                padding: 30px;
                background-color: @component-background;
                border-radius: 10px;
                box-shadow: rgba(117, 149, 178, 0.24) 0px 0px 24px 0px;

                .ivu-form-item {
                    .ivu-form-item-content {
                        .ivu-tag {
                            position: absolute;
                            right: 5px;
                            top: 7px;
                        }

                        .captcha {
                            .ivu-input {
                                padding-left: 32px;
                                background-image: url(../assets/images/input_security.png);
                                background-position: 7px center;
                                background-repeat: no-repeat;
                                background-size: 17px 17px;
                            }

                            .ivu-input-group-append {
                                padding: 0;
                                background-color: #FFEDBB;

                                img.img_code {
                                    display: inline-block;
                                    height: 31px;
                                    margin-top: 5px;
                                }
                            }
                        }

                        .ivu-tooltip {
                            float: right;
                            color: @primary-color;
                        }
                    }
                }

                .checkbox.ivu-form-item {
                    margin-bottom: 8px;
                }

                .ivu-divider {
                    margin-top: 130px;
                    margin-bottom: 10px;
                }

                .tips {
                    color: #999;
                }
            }
        }

        .copyright {
            position: fixed;
            bottom: 20px;
            display: block;
            width: 100%;
            text-align: center;
            color: @text-color-secondary;
            line-height: 20px;
        }
    }
}
</style>
